import domReady from '@roots/sage/client/dom-ready';

domReady(async () => {

  // Get the search trigger element
  const searchTrigger = document.getElementById('js-openSearch');

  // Get the close trigger element
  const closeTrigger = document.getElementById('js-closeSearch');

  // Get the div to be opened/closed
  const searchDiv = document.getElementById('js-searchMenu');

  // Add click event listener to the search trigger
  searchTrigger.addEventListener('click', () => {
    // Toggle the visibility of the search div
    searchDiv.classList.toggle('closed');

    // Check if the search div is hidden
    if (searchDiv.classList.contains('closed')) {
      // If search div is hidden, add the "active" class to the search trigger
      searchTrigger.classList.remove('active');
    } else {
      // If search div is visible, remove the "active" class from the search trigger
      searchTrigger.classList.add('active');
    }
  });

  // Add click event listener to the close trigger
  closeTrigger.addEventListener('click', () => {
    // Hide the search div
    searchDiv.classList.add('closed');

    // Remove the "active" class from the search trigger
    searchTrigger.classList.remove('active');
  });

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);