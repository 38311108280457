import domReady from '@roots/sage/client/dom-ready';
import Swiper, { Autoplay, Navigation, Pagination, Controller } from "swiper";

/**
 * Application entrypoint
 */
domReady(async () => {
	//Swiper
	const singleSlider = document.querySelector(".single-slider");
	if (singleSlider) {
		let swiper = new Swiper(".single-slider", {
			modules: [Autoplay, Navigation, Pagination, Controller],
			autoplay: false,
			loop: true,
			slidesPerView: 1,
			navigation: {
				nextEl: `.swiper-button-next`,
				prevEl: `.swiper-button-prev`
			},
			pagination: {
				el: `.swiper-pagination-dots`,
				type: 'bullets',
				clickable: true,
			},
		});
	}
	
	//Swiper
	var slider1 = new Swiper('.slider1', {
		modules: [Autoplay, Navigation, Pagination, Controller],
		slidesPerView: 1,
		loop: true,
		loopedSlides: 3,
		parallax: true,
		effect: 'fade',
		allowTouchMove: false
	});
	
	var slider2 = new Swiper('.slider2', {
		modules: [Autoplay, Navigation, Pagination, Controller],
		slidesPerView: 1,
		loop: true,
		loopedSlides: 3,
		navigation: {
			nextEl: '.slider1-next',
			prevEl: '.slider1-prev',
		},
		pagination: {
			el: '.caption-swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		controller: {
			control: slider1,
		},
	});



});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
