import domReady from '@roots/sage/client/dom-ready';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
import * as te from 'tw-elements';
import './hours.js';
import './search.js';
import './sliders.js';

/**
 * Application entrypoint
 */
domReady(async () => {
    const swiperGenerator = slider => {
        const sliderID = slider.dataset.id;
        const slidesPerView = slider.dataset.slidesPerView;
        const autoPlay = slider.dataset.autoplay === 'false';
        const responsive = slider.dataset.responsive === 'true';
        return new Swiper(`.swiper-${sliderID}`, {
            modules:[Autoplay, Navigation, Pagination],
            autoplay: autoPlay,
            speed: 400,
            observer: true,
            observeParents: true,
            loop: true,
            slidesPerView: 1,
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                720: {
                    slidesPerView: responsive ? 2 : 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView:  responsive ? slidesPerView : 1,
                    spaceBetween: 15,
                },
            },
            navigation: {
                nextEl: `.swiper-button-next-${sliderID}`,
                prevEl: `.swiper-button-prev-${sliderID}`
            },
            pagination: {
                el: `.swiper-pagination-${sliderID}`,
                type: 'bullets',
                clickable: true,
            },
        });
    }

    // Get all swiper wrappers and append "swiper-slide" class to its children
    // This will enable all elements passed through the { slot } to be a swiper slide
    const makeSwiperSlides = (children) => {
        for (let child of children) {
            child.classList.add('swiper-slide');
        }
    }
    const allSliderWrapperChildren = document.querySelectorAll('.swiper-wrapper > div');
    makeSwiperSlides(allSliderWrapperChildren)

    // Get every swiper instance by the swiper class
    const allSliders = document.querySelectorAll('.swiper');
    // iterate over all nodes with the swiper class and apply Swiper on each one.
    for (let slider of allSliders) {
        swiperGenerator(slider)
    }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
