import domReady from '@roots/sage/client/dom-ready';

/**
 *JS Live Hours
// This code dynamically injects the live hours and status values into the DOM so that they are not cached and consistently accurate
 */

domReady(async () => {
  	
	let apiURLs = ['todays-hours', 'open-status']
	apiURLs.forEach(updateHeaderWidget)

	function updateHeaderWidget(target) {
		let apiURL = window.location.origin + '/wp-json/live-hours/v1/' + target +'/?';

		fetch(apiURL + new URLSearchParams({
			//since they have only one branch in this widget this can be hardcoded
			branch_id: main_branch_id,
		}), {
			method: 'GET',
	cache: 'no-cache',
			headers: {
			'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
			//console.log(data);
	//remove JSON quotes
	// eslint-disable-next-line
			document.getElementById(target).innerText = data.replace(/\"/g, "");
			})
			.catch((error) => {
			console.error(error);
			});
	};

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
